import React from 'react'

export default function About() {
    return (
        <>
            <div id="about" class="container-fluid wow fadeIn" data-wow-duration="1.5s" >
                <div class="row">
                    <div class="col-lg-6 has-img-bg"></div>
                    <div class="col-lg-6">
                        <div class="row justify-content-center">
                            <div class="col-sm-8 py-5 my-5">
                                <h2 class="mb-4">About Us</h2>
                                <p>A good restaurant maintains a high standard of food quality and guarantees that clients receive the same level of service with each meal. Serving outstanding meals may help your restaurant build a positive image and entice customers to return for more. A good cook knows your visitors' demands and collaborates closely with the kitchen staff to guarantee that each guest receives their dish just as they wanted it.
</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="gallary" class="text-center bg-dark text-light has-height-md middle-items wow fadeIn">
                <h2 class="section-title">OUR MENU</h2>
            </div>
            <div class="gallary row">
                <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                    <img src="assets/imgs/EAT_ALOT/EAT_A_LOT_1.png" alt="template by DevCRID http://www.devcrud.com/" class="gallary-img" />
                    <a href="#" class="gallary-overlay">
                        <i class="gallary-icon ti-plus"></i>
                    </a>
                </div>
                <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                    <img src="assets/imgs/EAT_ALOT/EAT_A_LOT_2.png" alt="template by DevCRID http://www.devcrud.com/" class="gallary-img" />
                    <a href="#" class="gallary-overlay">
                        <i class="gallary-icon ti-plus"></i>
                    </a>
                </div>
                <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                    <img src="assets/imgs/EAT_ALOT/EAT_A_LOT_3.png" alt="template by DevCRID http://www.devcrud.com/" class="gallary-img" />
                    <a href="#" class="gallary-overlay">
                        <i class="gallary-icon ti-plus"></i>
                    </a>
                </div>
                <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                    <img src="assets/imgs/EAT_ALOT/EAT_A_LOT_4.png" alt="template by DevCRID http://www.devcrud.com/" class="gallary-img" />
                    <a href="#" class="gallary-overlay">
                        <i class="gallary-icon ti-plus"></i>
                    </a>
                </div>
                <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                    <img src="assets/imgs/EAT_ALOT/EAT_A_LOT_5.png" alt="template by DevCRID http://www.devcrud.com/" class="gallary-img" />
                    <a href="#" class="gallary-overlay">
                        <i class="gallary-icon ti-plus"></i>
                    </a>
                </div>
                <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                    <img src="assets/imgs/EAT_ALOT/EAT_A_LOT_6.png" alt="template by DevCRID http://www.devcrud.com/" class="gallary-img" />
                    <a href="#" class="gallary-overlay">
                        <i class="gallary-icon ti-plus"></i>
                    </a>
                </div>
                <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                    <img src="assets/imgs/EAT_ALOT/EAT_A_LOT_7.png" alt="template by DevCRID http://www.devcrud.com/" class="gallary-img" />
                    <a href="#" class="gallary-overlay">
                        <i class="gallary-icon ti-plus"></i>
                    </a>
                </div>
                <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                    <img src="assets/imgs/EAT_ALOT/EAT_A_LOT_8.png" alt="template by DevCRID http://www.devcrud.com/" class="gallary-img" />
                    <a href="#" class="gallary-overlay">
                        <i class="gallary-icon ti-plus"></i>
                    </a>
                </div>
                <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                    <img src="assets/imgs/gallary-9.jpg" alt="template by DevCRID http://www.devcrud.com/" class="gallary-img" />
                    <a href="#" class="gallary-overlay">
                        <i class="gallary-icon ti-plus"></i>
                    </a>
                </div>
                <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                    <img src="assets/imgs/gallary-10.jpg" alt="template by DevCRID http://www.devcrud.com/" class="gallary-img" />
                    <a href="#" class="gallary-overlay">
                        <i class="gallary-icon ti-plus"></i>
                    </a>
                </div>
                <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                    <img src="assets/imgs/gallary-11.jpg" alt="template by DevCRID http://www.devcrud.com/" class="gallary-img" />
                    <a href="#" class="gallary-overlay">
                        <i class="gallary-icon ti-plus"></i>
                    </a>
                </div>
                <div class="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                    <img src="assets/imgs/gallary-12.jpg" alt="template by DevCRID http://www.devcrud.com/" class="gallary-img" />
                    <a href="#" class="gallary-overlay">
                        <i class="gallary-icon ti-plus"></i>
                    </a>
                </div>
            </div>
            {/* <div class="container-fluid has-bg-overlay text-center text-light has-height-lg middle-items" id="book-table">
        <div class="">
            <h2 class="section-title mb-5">BOOK A TABLE</h2>
            <div class="row mb-5">
                <div class="col-sm-6 col-md-3 col-xs-12 my-2">
                    <input type="email" id="booktable" class="form-control form-control-lg custom-form-control" placeholder="EMAIL"/>
                </div>
                <div class="col-sm-6 col-md-3 col-xs-12 my-2">
                    <input type="number" id="booktable" class="form-control form-control-lg custom-form-control" placeholder="NUMBER OF GUESTS" max="20" min="0"/>
                </div>
                <div class="col-sm-6 col-md-3 col-xs-12 my-2">
                    <input type="time" id="booktable" class="form-control form-control-lg custom-form-control" placeholder="EMAIL"/>
                </div>
                <div class="col-sm-6 col-md-3 col-xs-12 my-2">
                    <input type="date" id="booktable" class="form-control form-control-lg custom-form-control" placeholder="12/12/12"/>
                </div>
            </div>
            <a href="#" class="btn btn-lg btn-primary" id="rounded-btn">FIND TABLE</a>
        </div>
    </div> */}
            <div id="blog" class="container-fluid bg-dark text-light py-5 text-center wow fadeIn">
                <h2 class="section-title py-5">EVENTS AT THE EAT ALOT </h2>
                <div class="row justify-content-center">
                    <div class="col-sm-7 col-md-4 mb-5">
                        <ul class="nav nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#foods" role="tab" aria-controls="pills-home" aria-selected="true">Foods</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#juices" role="tab" aria-controls="pills-profile" aria-selected="false">Juices</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="foods" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card bg-transparent border my-3 my-md-0">
                                    <img src="assets/imgs/blog-1.jpg" alt="template by DevCRID http://www.devcrud.com/" class="rounded-0 card-img-top mg-responsive" />
                                    <div class="card-body">
                                        <h1 class="text-center mb-4"><a href="#" class="badge badge-primary">Rs.80</a></h1>
                                        <h4 class="pt20 pb20">Cheese Burger </h4>
                                        <p class="text-white">A cheeseburger is a hamburger with a slice of melted cheese on top of the aloo patty, added near the end of the cooking time.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card bg-transparent border my-3 my-md-0">
                                    <img src="assets/imgs/blog-2.jpg" alt="template by DevCRID http://www.devcrud.com/" class="rounded-0 card-img-top mg-responsive" />
                                    <div class="card-body">
                                        <h1 class="text-center mb-4"><a href="#" class="badge badge-primary">Rs.140</a></h1>
                                        <h4 class="pt20 pb20">Leafy Green Salad</h4>
                                        <p class="text-white">There are five elements to a perfect salad: greens, sweetness, creaminess, crunchiness, and dressing.and they do not contain cholesterol.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card bg-transparent border my-3 my-md-0">
                                    <img src="assets/imgs/blog-3.jpg" alt="template by DevCRID http://www.devcrud.com/" class="rounded-0 card-img-top mg-responsive" />
                                    <div class="card-body">
                                        <h1 class="text-center mb-4"><a href="#" class="badge badge-primary">Rs.110</a></h1>
                                        <h4 class="pt20 pb20"> Makhni Baurger</h4>
                                        <p class="text-white">Made with butter, tomato, onion, garlic with a hint of fenugreek leaves and a blend of carefully selected high quality, locally sourced ingredients.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="juices" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div class="row">
                            <div class="col-md-4 my-3 my-md-0">
                                <div class="card bg-transparent border">
                                    <img src="assets/imgs/blog-4.jpg" alt="template by DevCRID http://www.devcrud.com/" class="rounded-0 card-img-top mg-responsive" />
                                    <div class="card-body">
                                        <h1 class="text-center mb-4"><a href="#" class="badge badge-primary">Rs.50</a></h1>
                                        <h4 class="pt20 pb20">Mojito</h4>
                                        <p class="text-white">This is a genuine mojito recipe. I proportioned the recipe for one serving, but you can easily double it to create a pitcher full. It's a great drink to have on hot summer days. But be cautious when consuming it.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 my-3 my-md-0">
                                <div class="card bg-transparent border">
                                    <img src="assets/imgs/blog-5.jpg" alt="template by DevCRID http://www.devcrud.com/" class="rounded-0 card-img-top mg-responsive" />
                                    <div class="card-body">
                                        <h1 class="text-center mb-4"><a href="#" class="badge badge-primary">Rs.150</a></h1>
                                        <h4 class="pt20 pb20">Straberry Pulp</h4>
                                        <p class="text-white">Strawberry pulp extraction from NON-GMO, sound ripened washed strawberries by mechanical process, screening and separation to remove particles and contaminants. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 my-3 my-md-0">
                                <div class="card bg-transparent border">
                                    <img src="assets/imgs/blog-6.jpg" alt="template by DevCRID http://www.devcrud.com/" class="rounded-0 card-img-top mg-responsive" />
                                    <div class="card-body">
                                        <h1 class="text-center mb-4"><a href="#" class="badge badge-primary">Rs. 60</a></h1>
                                        <h4 class="pt20 pb20">Mango Juice</h4>
                                        <p class="text-white">Mango juice is high in nutrients such as carbs, fiber, sugars, minerals, and vitamins. Minerals such as calcium, potassium, phosphorus, zinc, iron, and so on. It includes  gallic acid, and mangiferin. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="testmonial" class="container-fluid wow fadeIn bg-dark text-light has-height-lg middle-items">
                <h2 class="section-title my-5 text-center">REVIEWS</h2>
                <div class="row mt-3 mb-5">
                    <div class="col-md-4 my-3 my-md-0">
                        <div class="testmonial-card">
                            <h3 class="testmonial-title">Ritesh Kaushal</h3>
                            <h6 class="testmonial-subtitle">Costomer</h6>
                            <div class="testmonial-body">
                                <p>It’s been pleasure to have dinner at eat Alot on occasion of my birthday we have celebrated here and n joy the experience was amazing thanks to eat alot who help us with the menu and service soon we will be back</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 my-3 my-md-0">
                        <div class="testmonial-card">
                            <h3 class="testmonial-title">Rupali Gupta</h3>
                            <h6 class="testmonial-subtitle">Employee</h6>
                            <div class="testmonial-body">
                                <p>I have tried the food it’s awesome. They have homemade food. I really loved the food and my family members all delighted to eat such a mouthwatering and tasty food.was amazing experience with eat alot such a beautiful venue.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 my-3 my-md-0">
                        <div class="testmonial-card">
                            <h3 class="testmonial-title">Sahil Agrawal </h3>
                            <h6 class="testmonial-subtitle">Businessman</h6>
                            <div class="testmonial-body">
                                <p>Good food was our saviour during college. We webt their for lunch almost every day. Our go to dish was their red sauce pasta and cheese jalapeno sandwich. Their pasta tastes better than some of the poshest restaurants in Prayagraj. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="contact" class="container-fluid bg-dark text-light border-top wow fadeIn">
            <h2 class="section-title my-5 text-center">Contact US</h2>
                <div class="row">
                    <div class="col-md-6">
                        <div class="card ">
                            <div class="card-body">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.4620034560885!2d81.83268177426883!3d25.45624412116178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399acac2efe53005%3A0x7291bb14e98e4ea6!2sPC%20Jeweller%20Prayagraj!5e0!3m2!1sen!2sin!4v1697105309112!5m2!1sen!2sin" width="700" height="350" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row justify-content-center">
                            <div class="col-sm-8 py-5 my-5">
                                <h3>FIND US</h3>
                                <p>Food, in the end, in our own tradition, is something holy. It's not about nutrients and calories. It's about sharing. It's about honesty. It's about identity. & a restaurant is a place where you go to celebrate a special occasion, or to create a new one.</p>
                                <div class="text-muted">
                                    <p><span class="ti-location-pin pr-3"></span>Behind PC Jewellers SP Marg Civil Lines Prayagraj UP India Pin - 210018</p>
                                    <p><span class="ti-support pr-3"></span>+91 8018218713 </p>
                                    <p><span class="ti-email pr-3"></span>info@eatalot.vapsoft.org</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
