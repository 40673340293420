
import React from 'react'
import Navbar from './Components/Navbar';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
 
export default function App() {
  return (
    <>
    <Navbar/>
    <Header/>
    <About/>
    <Footer/>
    

    </>
    
  );
}

