import React from 'react'

export default function Footer() {
 return (
    <div className="container-fluid bg-dark text-light has-height-md middle-items border-top text-center wow fadeIn">
        <div className="row">
            <div className="col-sm-4">
                <h3>EMAIL US</h3>
                <p className="text-muted">info@eatalot.in</p>
            </div>
            <div className="col-sm-4">
                <h3>CALL US</h3>
                <p className="text-muted">+91 8018218713</p>
            </div>
            <div className="col-sm-4">
                <h3>FIND US</h3>
                <p className="text-muted">Behind PC Jewellers SP Marg Civil Lines Prayagraj UP India Pin - 210018</p>
            </div>
        </div>
    </div>
  );
}
